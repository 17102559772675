<template>
  <section class="appointment-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="appointmentStore.error !== null"
    >
      <h4 class="alert-heading">
        Error fetching appointment data
      </h4>
    </b-alert>
    <b-overlay
      :show="appointmentStore.isPending || appointmentDeleteStore.isPending || updateAppointmentStore.isPending"
      variant="secondary"
      spinner-variant="primary"
      blur="1"
      opacity=".75"
      rounded="lg"
    >
      <b-row
        v-if="(appointmentStore.isPending && !appointment.id) || appointment.id"
        class="appointment-preview"
      >
        <!-- Col: Left appointment date -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class="appointment-preview-card"
          >
            <!-- Header -->
            <b-card-body class="appointment-padding pb-0">
              <div class="d-flex justify-content-between flex-md-row flex-column appointment-spacing mt-0">
                <!-- Header: Left Content -->
                <div>
                  <h4 class="font-weight-bold">
                    Appointment #{{ appointment.id }}
                  </h4>
                  <p class="card-text mb-25">
                    Time: {{ appointment.startsAt | hoursUsLocale }} - {{ appointment.endsAt | hoursUsLocale }}
                  </p>
                  <p class="card-text mb-25">
                    Date: {{ appointment.startsAt | dateUsLocale }}
                  </p>
                </div>
              </div>
            </b-card-body>
            <!-- Spacer -->
            <hr class="appointment-spacing">
            <!-- appointment customer -->
            <b-card-body
              class="appointment-padding pt-0"
            >
              <b-row class="appointment-spacing">
                <!-- Col: appointment To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
                >
                  <h6 class="mb-25">
                    {{ appointment.customer.name }} {{ appointment.customer.lastName }}
                  </h6>
                  <p
                    v-if="appointment.customer.email"
                    class="card-text mb-25"
                  >
                    {{ appointment.customer.email }}
                  </p>
                  <p
                    v-if="appointment.customer.phone"
                    class="card-text mb-25"
                  >
                    {{ appointment.customer.phone }}
                  </p>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- appointment description: Table -->
            <b-table-lite
              responsive
              :items="appointment.services"
              :fields="['serviceDescription', 'price', 'duration']"
            >
              <template #cell(serviceDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.name }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.description }}
                </b-card-text>
              </template>
              <template #cell(price)="data">
                ${{ data.item.price | price }}
              </template>
              <template #cell(duration)="data">
                {{ data.item.duration }} min
              </template>
            </b-table-lite>

            <!-- appointment Description: Total -->
            <b-card-body class="appointment-padding pb-0">
              <b-row>
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="12"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="appointment-total-wrapper">
                    <div class="appointment-total-item">
                      <p class="appointment-total-title">
                        Total:
                      </p>
                      <p class="appointment-total-amount">
                        ${{ appointment.services | sumPrice }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="appointment-spacing">

            <!-- Note -->
            <b-card-body class="appointment-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <span>{{ appointment.note }}</span>
              <span v-if="!appointment.note">no note</span>
            </b-card-body>

            <!-- Review -->
            <!-- appointment description: Table -->
            <b-table
              v-if="rate[0]"
              ref="reviews"
              responsive
              :items="rate"
              :fields="fields"
              class="mt-1"
            >
              <template #cell(review)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.message }}
                </b-card-text>
              </template>
              <template #cell(rate)="data">
                <span class="d-block d-md-inline-block">
                  <span class="d-block d-md-inline-block hearts-margin">
                    <b-form-rating
                      id="rating"
                      v-model="data.item.rate"
                      icon-empty="heart"
                      icon-half="heart-half"
                      icon-full="heart-fill"
                      inline
                      readonly
                      precision="2"
                      no-border
                      variant="primary"
                      class="no-background font-medium-2 pl-0"
                    />
                  </span>
                </span>
              </template>
              <template #cell(action)="data">
                <b-button-group>
                  <b-button
                    v-if="[reviewStatuses.Pending, reviewStatuses.Rejected].includes(data.item.state)"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    v-b-tooltip.hover.top="'Approve this review'"
                    variant="outline-secondary"
                    class="mt-0"
                    size="sm"
                    @click="updateReviewState(data.item.id, reviewStatuses.Approved)"
                  >
                    <feather-icon icon="EditIcon" />
                    <span>Approve</span>
                  </b-button>

                  <b-button
                    v-if="[reviewStatuses.Pending, reviewStatuses.Approved].includes(data.item.state)"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    v-b-tooltip.hover.top="'Reject this review'"
                    variant="outline-danger"
                    class="mt-0"
                    size="sm"
                    @click="updateReviewState(data.item.id, reviewStatuses.Rejected)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span>Reject</span>
                  </b-button>
                </b-button-group>
              </template>
              <template #cell(createdAt)="data">
                {{ data.item.createdAt | dateAndHoursUsLocale }}
              </template>
            </b-table>
          </b-card>
        </b-col>

        <!-- Right Col: Edit -->
        <b-col
          cols="12"
          md="4"
          xl="3"
        >
          <b-card>
            <h4 class="font-weight-bold">
              Payments
            </h4>
            <span v-if="appointment.payment.length === 0">
              No payments
            </span>
            <b-list-group flush>
              <b-list-group-item
                v-for="(payment, index) in appointment.payment"
                :key="index"
                class="d-flex flex-column"
              >
                <span>
                  ${{ payment.amount }} ( {{ payment.status }} )
                </span>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              @click="showAppointmentModal(appointment)"
            >
              Edit
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <stylist-calendar-appointment-modal v-if="appointmentModalStore.show" />
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BTable, BCardText, BButton, BButtonGroup, BAlert, BOverlay, BListGroup, BListGroupItem, VBToggle, BFormRating, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import defaultAppointmentCalendarEvent from '@/components/stylist/stylist-calendar/defaultAppointmentCalendarEvent'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import StylistReviewStatusDictionary from '@/dictionaries/stylistReviewStatusDictionary'
import StylistCalendarAppointmentModal from '@/components/stylist/stylist-calendar/StylistCalendarAppointmentModal.vue'
import { APPOINTMENT_LIST } from '@/router/routes/routes-names'

export default {
  filters: {
    dateUsLocale(date) {
      return moment(date, 'YYYY-MM-DD H:mm').format('MMMM D, yyyy')
    },
    hoursUsLocale(date) {
      return moment(date, 'YYYY-MM-DD H:mm').format('h:mm a')
    },
    dateAndHoursUsLocale(date) {
      return moment(date, 'YYYY-MM-DD H:mm').format('MMMM D, yyyy h:mm a')
    },
    sumPrice(selectedServices) {
      let price = 0
      selectedServices.forEach(service => {
        price += +service.price
      })
      return price.toFixed(2)
    },
    price(price) {
      return Number(price).toFixed(2)
    },
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BTable,
    BCardText,
    BButton,
    BButtonGroup,
    BOverlay,
    BAlert,
    BListGroup,
    BListGroupItem,
    BFormRating,
    StylistCalendarAppointmentModal,
  },
  data() {
    return {
      appointment: defaultAppointmentCalendarEvent,
      appointmentData: null,
      paymentDetails: {},
      stylistReviewStatusDictionary: StylistReviewStatusDictionary,
      reviewStatuses: {},
      rate: [],
      fields: [
        { key: 'review' },
        { key: 'rate' },
        { key: 'action', thStyle: { width: '18%' } },
        { key: 'createdAt', thStyle: { width: '15%' } },
      ],
    }
  },
  computed: {
    ...mapState('StylistAppointmentStoreModule', {
      appointmentStore: state => state.appointment,
    }),
    ...mapState('StylistCalendarStoreModule', {
      appointmentModalStore: state => state.appointmentModal,
      appointmentDeleteStore: state => state.appointmentDelete,
      updateAppointmentStore: state => state.updateAppointment,
    }),
  },
  watch: {
    appointmentStore: {
      deep: true,
      handler(appointment) {
        if (appointment.response) {
          this.mapAppointment(appointment)
        }
      },
    },
    updateAppointmentStore: {
      deep: true,
      handler(newUpdateAppointmentStore) {
        if (newUpdateAppointmentStore.response) {
          this.mapAppointment(newUpdateAppointmentStore)
        }
      },
    },
    appointmentDeleteStore: {
      deep: true,
      handler(appointmentDelete) {
        if (appointmentDelete.id) {
          this.$router.push({ name: APPOINTMENT_LIST })
        }
      },
    },
  },
  created() {
    this.reviewStatuses = this.mapStatuses(StylistReviewStatusDictionary)
  },
  mounted() {
    this.fetchAppointment({ id: this.$router.currentRoute.params.id })
  },
  methods: {
    ...mapActions('StylistReviewStoreModule', [
      'updateReview',
    ]),
    ...mapActions('StylistAppointmentStoreModule', [
      'fetchAppointment',
    ]),
    getVariant(status) {
      switch (status) {
        case this.reviewStatuses.Pending:
          return 'light-info'
        case this.reviewStatuses.Rejected:
          return 'light-danger'
        case this.reviewStatuses.Approved:
          return 'light-success'
        default:
          return 'light-primary'
      }
    },
    mapStatuses(stylistReviewStatusDictionary) {
      const reviewStatuses = []
      Object.keys(stylistReviewStatusDictionary).forEach(key => {
        const statusName = stylistReviewStatusDictionary[key]
        reviewStatuses[statusName] = +key
      })
      return reviewStatuses
    },
    mapAppointment(appointment) {
      this.appointment = appointment.response
      if (appointment.response.rate) {
        this.rate[0] = appointment.response.rate
        if (this.$refs.reviews) {
          this.$refs.reviews.refresh()
        }
      }
    },
    async updateReviewState(id, state) {
      try {
        await this.updateReview({ id, state })
        await this.fetchAppointment({ id: this.$router.currentRoute.params.id })
      } catch (error) {
        this.error()
      }
    },
    showAppointmentModal(appointment) {
      this.$store.dispatch('StylistCalendarStoreModule/showAppointmentModal', {
        start_date: moment(appointment.startsAt, 'yyyy-MM-DDTHH:mm:ss').format('yyyy-MM-DD HH:mm:ss'),
        ...appointment,
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-appointment.scss";
.no-background {
  background: transparent !important;
}
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  .appointment-preview-wrapper {
    .row.appointment-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .appointment-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
